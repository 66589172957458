import axios from 'axios'
import errorCode from '@/utils/errorCode'
import { localStore } from '@/utils/storage'
import Vue from 'vue'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
  timeout: 50000
})

// 请求拦截
service.interceptors.request.use(
  config => {
    const tokenInfo = localStore.get('tokenInfo')
    if (tokenInfo) {
      config.headers['cnbmil-token'] = `${tokenInfo.token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    // 是否返回arrayBuffer类型
    const isArrayBuffer = response.config.headers.responseType || response.config.responseType
    if (isArrayBuffer && response.status === 200) {
      return response.data
    }
    if (response.config.url.indexOf('downLoad') > -1) {
      return response.data
    }
    const { code, msg, access_token } = response.data
    // 登录成功后返回数据没有code, 所以根据access_token判断
    if (code === 200 || access_token) {
      // 返回的提示信息太大会导致奔溃, 进行截取下载成文件处理
      if (msg && msg.length > 150) {
        exportFile('提示信息', msg)
        // 截取返回提示语句
        response.data.msg = msg.slice(0, 150) + '<p style="font-weight: bold">更多信息,请查看下载下来的提示信息文件</p>'
      }
      // 直接返回全部数据
      return response.data
    } else {
      Vue.prototype.$message({
        message: msg || '请求超时',
        type: 'error',
        duration: 3 * 1000,
        dangerouslyUseHTMLString: true
      })
      return Promise.reject(response.data)
    }
  },
  error => {
    const { status, data } = error.response
    const msg = errorCode[status]
    if (status === 500) {
      /* process.env.NODE_ENV === 'development' && */ Vue.prototype.$message.error(data.message)
      console.log('message', data.message)
      if (data.message === '请先登录!') {
        localStore.remove('tokenInfo')
        window.location.href = '#/login'
      }
    } else {
      console.error(msg)
    }
    return Promise.reject(error)
  }
)

export default service
