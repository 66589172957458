import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/index/index";

Vue.use(VueRouter);

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: 'home/enterBidAnnouncement',
  },
  {
    path: '/home',
    name: 'IndexPage',
    component: HomeView,
    children: [
      {
        path: '/home/enterBidAnnouncement',
        name: 'EnterBidAnnouncement',
        component: () =>
          import(/* webpackChunkName: "enterBidAnnouncement" */ '@/views/EnterBidAnnouncement/index')
      },
      {
        path: '/home/changeAnnouncement',
        name: 'ChangeAnnouncement',
        component: () =>
          import(/* webpackChunkName: "changeAnnouncement" */ '@/views/ChangeAnnouncement/index')
      },
      {
        path: '/home/disBidAnnouncement',
        name: 'DisBidAnnouncement',
        component: () =>
          import(/* webpackChunkName: "disBidAnnouncement" */ '@/views/DisBidAnnouncement/index')
      },
      {
        path: '/home/winBidAnnouncement',
        name: 'WinBidAnnouncement',
        component: () =>
          import(/* webpackChunkName: "winBidAnnouncement" */ '@/views/WinBidAnnouncement/index')
      },
      {
        path: '/home/viewBidDetail',
        name: 'ViewBidDetail',
        component: () =>
          import(/* webpackChunkName: "viewBidDetail" */ '@/views/ViewBidDetail/index')
      },
      {
        path: '/register',
        name: 'Register',
        component: () =>
          import(/* webpackChunkName: "register" */ '@/views/Register/index')
      },
      {
        path: '/center',
        name: 'Center',
        component: () =>
          import(/* webpackChunkName: "center" */ '@/views/myCenter/index')
      },
      {
        path: '/forget',
        name: 'Forget',
        component: () =>
          import(/* webpackChunkName: "forget" */ '@/views/ForgetPassword/index')
      },
      {
        path: '/supplierApply',
        name: 'SupplierApply',
        component: () =>
          import(/* webpackChunkName: "supplierApply" */ '@/views/SupplierApply/index')
      },
      {
        path: '/viewBidDetail',
        name: 'ViewBidDetail',
        component: () =>
          import(/* webpackChunkName: "viewBidDetail" */ '@/views/ViewBidDetail/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/Login/index')
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
