import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: 'bidUser/filter/login',
    method: 'post',
    data,
    headers: { 'content-type': 'application/json' }
  })
}

// 短信登录
export function phoneLogin(data) {
  return request({
    url: 'bidUser/filter/loginBySms',
    method: 'post',
    data,
    headers: { 'content-type': 'application/json' }
  })
}

// 退出
export function logout() {
  return request({
    url: 'bidUser/filter/logout',
    method: 'delete'
  })
}

// 注册
export function httpRegister(data) {
  return request({
    url: 'bidUser/filter/save',
    method: 'post',
    data
  })
}

// 修改个人信息
export function httpEditCenter(data) {
  return request({
    url: 'bidUser',
    method: 'put',
    data
  })
}

// 发送短信
export function httpSendSms(data) {
  return request({
    url: 'aliyunSms/send',
    method: 'post',
    data
  })
}

// 手机登录短信
export function httpPhoneLoginSendSms(data) {
  return request({
    url: '/bidUser/filter/sendVerifyCodeWhenLogin',
    method: 'post',
    data
  })
}

// 短信校验
export function httpValidateSms(data) {
  return request({
    url: 'bidUser/filter/resetPwdCheckBefore',
    method: 'post',
    data
  })
}

// 重置密码
export function httpResetPassword(data) {
  return request({
    url: 'bidUser/resetPwd',
    method: 'put',
    data
  })
}

// 申请加入供应商
export function httpApplyJoinSupplier(data) {
  return request({
    url: 'bidEnterpriseInfo',
    method: 'post',
    data
  })
}

// 申请准入 -- 修改
export function httpUpdateApplyJoinSupplier(data) {
  return request({
    url: 'bidEnterpriseInfo',
    method: 'put',
    data
  })
}

// 查下是否已准入供应商
export function httpIsApplySupplier(id) {
  return request({
    url: `bidEnterpriseInfo/getByBidUserId/${id}`,
    method: 'get'
  })
}