import Vuex from '@/store'

const state = {
  nowBidTabs: 1
}

const mutations = {
  CHANGE_BID_TAB: (state, nowActiveTab) => {
    state.nowBidTabs = nowActiveTab
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
