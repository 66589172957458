<!-- 首页 -->
<template>
  <div class="home">
    <!-- top -->
    <section class="header">
      <div class="top">
        <div class="top-logo" @click="backHome">
          <img src="../../assets/logo.png">
          <div class="top-text">
            <p>中建材物资有限公司</p>
            <p>CNBM Material Company Limited</p>
          </div>
        </div>
        <div class="top-options" v-if="!isLogin">
          <p @click="jumpLogin">登录</p>
          <span>|</span>
          <p @click="jumpRegister">注册</p>
        </div>
        <div class="top-options" v-else>
          <p @click="jumpSupplierApply">供应商准入申请</p> 
          <p @click="jumpCenter">{{ username }}</p>
          <span>|</span>
          <p @click="logout">退出</p>
        </div>
      </div>
    </section>
    <!-- content -->
    <nav class="content">
     <div class="content-tabs">
       <div class="content-title">招采项目</div>
       <!-- <router-link class="tab" to="/home/enterBidAnnouncement">招标公告</router-link>
       <router-link class="tab" to="/home/changeAnnouncement">变更公告</router-link>
       <router-link class="tab" to="/home/winBidAnnouncement">中标公告</router-link>
       <router-link class="tab" to="/home/disBidAnnouncement">废标公告</router-link> -->
       <div
        v-for="(item, index) in bidList"
        :key="index" class="tab"
        :class="[ nowAnn === item.type ? 'active' : '' ]"
        @click="jumpAnn(item)"
      >{{ item.title }}</div>
     </div>
    </nav>
    <router-view />

    <!-- bottom -->
    <div style="height: 100px"></div>
    <section class="bottom">
      <p>Copyright 2018 www.cnbmm.com 中建材物资有限公司 版权所有 All Rights Reserved</p>
      <p>公司地址: 深圳市罗湖区人民南路国际贸易中心大厦14楼 联系电话： 0755-82212899 电子邮件: cnbmit@cnbmit.com</p>
    </section>
  </div>
</template>

<script>
import { localStore } from '@/utils/storage'
import { logout } from '@/api/user'

export default {
  name: 'IndexPage',
  data() {
    return {
      isLogin: false, // 是否登录
      username: '', // 用户名
      bidList: [
        {
          title: '招标公告',
          name: 'EnterBidAnnouncement',
          type: 1
        },
        {
          title: '变更公告',
          name: 'ChangeAnnouncement',
          type: 2
        },
        {
          title: '废标公告',
          name: 'DisBidAnnouncement',
          type: 3
        },
        {
          title: '中标公告',
          name: 'WinBidAnnouncement',
          type: 4
        }
      ]
    }
  },
  computed: {
    nowAnn() {
      return this.$store.state.tabs.nowBidTabs
    }
  },
  created() {
    const tokenInfo = localStore.get('tokenInfo') || {}
    if (JSON.stringify(tokenInfo) === '{}') {
      this.isLogin = false
    } else {
      this.isLogin = true
      this.username = tokenInfo.username
    }
  },
  methods: {
    backHome() {
      this.$store.commit('tabs/CHANGE_BID_TAB', 1)
      this.$router.push({ path: '/' })
    },
    jumpLogin() {
      this.$router.push('/login')
    },
    jumpRegister() {
      this.$store.commit('tabs/CHANGE_BID_TAB', 0)
      this.$router.push('/register')
    },
    jumpCenter() {
      this.$store.commit('tabs/CHANGE_BID_TAB', 0)
      this.$router.push('/center')
    },
    jumpAnn(item) {
      this.$store.commit('tabs/CHANGE_BID_TAB', item.type)
      this.$router.push({ name: item.name })
    },
    logout() {
      this.$confirm('是否退出当前账号？', '提示', {
        confirmButtonText: '退出',
        cancelBUttonText: '取消'
      }).then(() => {
        localStore.remove('tokenInfo')
        this.backHome()
        setTimeout(() => {
          location.reload()
        }, 500)
      })
    },
    jumpSupplierApply() {
      this.$store.commit('tabs/CHANGE_BID_TAB', 0)
      this.$router.push({ name: 'SupplierApply' })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .header {
    background: url("../../assets/headerbg.png") no-repeat center;
    .top {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      .top-logo {
        display: flex;
        align-items: center;
        img {
          cursor: pointer;
          width: 40px;
          height: 52px;
        }
        .top-text {
          margin-left: 20px;
          :nth-child(1) {
            font-weight: bold;
            font-size: 26px;
          }
        }
      }
      .top-options {
        display: flex;
        align-items: center;
        margin-right: 20px;
        p {
          margin: 0 10px;
          font-size: 14px;
          color: #E22E34;
          cursor: pointer;
          &:hover {
            color: rgb(71, 71, 255);
            text-decoration: underline;
          }
        }
      }
    }
  }

  .content {
    background: #E22E34;
    .content-tabs {
      width: 80%;
      display: flex;
      margin: 0 auto;
      .tab {
        cursor: pointer;
        text-align: center;
        height: 50px;
        line-height: 50px;
        font-weight: bold;
        font-size: 14px;
        width: 100px;
        color: #fff;
        transition: all .2s;
      }
      .tab:hover {
        background: #aa1a23;
      }
      .active {
        background: #96020c;
      }
    }
    .content-title {
      margin-right: 20px;
      color: #fff;
      line-height: 50px;
      height: 50px;
      font-size: 26px;
    }
  }

  .bottom {
    margin: 0 auto;
    width: 100%;
    color: rgb(155, 155, 155);
    font-size: 12px;
    text-align: center;
  }
}
</style>
