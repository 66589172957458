<template>
  <div id="app">
<!--    <IndexPage />-->
    <router-view />
  </div>
</template>

<script>
import IndexPage from '@/views/index'
import { localStore } from './utils/storage';

export default {
  components: {
    IndexPage
  },
  data() {
    return {
      beforeUnload_time: '',
      _gap_time: ''
    }
  },
  watch: {
    $route(to, from) {
      if (to.name !== 'ViewBidDetail') {
        localStore.remove('viewDetailInfo')
      }
    }
  },
  mounted() {
    // 监听浏览器关闭
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
  },
  destroyed() {
    window.removeEventListener('beforeunload', e =>
      this.beforeunloadHandler(e)
    )
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {
    beforeunloadHandler() {
      this.beforeUnload_time = new Date().getTime()
    },
    unloadHandler() {
      this._gap_time = new Date().getTime() - this.beforeUnload_time
      //判断是窗口关闭还是刷新
      if (this._gap_time < 3) {
        localStore.remove('tokenInfo')
      //在这里编写浏览器关闭前的逻辑操作
      }
    }
  }
}

</script>

<style>
#app {
  /* background-color: #f7f7f7; */
  margin-bottom: 20px;
  min-width: 1080px;
}
</style>